import styles from './LinkList.module.scss'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useEffect, useLayoutEffect, useCallback, useState } from "react"
import { connect } from "react-redux"
import { scrollToNode } from "../../../../utils/animation"

const linksDefault = [
  {
    title: 'Как работает',
    href: 'home-advantages'
  },
  {
    title: 'FAQ',
    href: 'home-faq'
  },
  {
    title: 'Популярные направления',
    href: 'popular-destination'
  },
  {
    title: 'Отзывы',
    href: 'home-opinions'
  }
]

const defaultIds = [ 'home-faq', 'home-opinions', 'popular-destination', 'home-advantages' ]

const LinkList = ({
                    scroll,
                    windowInnerWidth,
                    sizes,
                    links = linksDefault,
                    ids = defaultIds,
                    lineCustomClass,
                    isNative,
                    classNameRoot
                  }) => {
  const [ activeId, setActiveId ] = useState(null)
  const [ lineWidth, setLineWidth ] = useState(0)
  const [ linePosition, setLinePosition ] = useState(0)
  const [ stopFindId, setStopFIndId ] = useState(false)
  let timeouts = {}

  useEffect(() => {
    return () => {
      try {
        Object.keys(timeouts).forEach(key => clearTimeout(timeouts[ key ]))
      } catch (e) {
        console.log('LinkList', e)
      }
    }
  }, [])

  useLayoutEffect(() => {
    findActiveId()
  }, [ scroll, stopFindId ])

  const findActiveId = async () => {
    if (stopFindId) return
    console.log(stopFindId)
    const items = await elementPosition()
    let _activeId = null
    for (let i = 0; i < items.length; i++) {
      let item = items[ i ]
      if (item.offsetTop - (windowInnerWidth >= sizes.mob ? 150 : 70) <= 0) {
        console.log({ item }, scroll)
        _activeId = items[ i ].id
        break
      }
      else {
        if (i === items.length - 1) _activeId = null
      }
    }
    setActiveId(_activeId)
  }

  const elementPosition = useCallback(async () => {
    try {
      return ids.map(item => {
        let elem = document.getElementById(item)
        return {
          offsetTop: elem.getBoundingClientRect().top,
          id: item
        }
      })
    } catch (e) {
      return await new Promise(res =>
        timeouts.firs = setTimeout(() => res(elementPosition()), 300)
      )
    }
  }, [])

  const linkHandler = (item) => {
    const id = item.href
    if (lineCustomClass) {
      scrollToNode(1000, document.getElementById(id), item.yOffset)
    }
    setStopFIndId(true)
    setActiveId(id)
    timeouts.secons = setTimeout(() => {
      setStopFIndId(false)
      //468
    }, 1000)
  }


  // const linkStyle = useCallback(() => {
  //   let ids = [ 'home-opinions', 'home-faq', 'popular-destination', 'home-advantages' ]
  //   const result = {}
  //   ids.forEach(item => {
  //     let elem = document.querySelector('.' + item + '-anchorLink')
  //     result[ item ] = {
  //       width: elem.getBoundingClientRect().width
  //     }
  //   })
  //   return result
  // }, [])

  return (
    <div className={ styles.root + (classNameRoot ? ' ' + classNameRoot : '') }>
      <div className={ styles.container }>
        { links.map((item, index) =>
          isNative ?
            <div className={ styles.item + (' ' + item.href + '-anchorLink') }
                 key={ index }
                 onClick={ linkHandler.bind(null, item) }>
              <div>{ item.title }</div>
              <div
                className={ styles.line + ' ' + (item.href === activeId ? ' ' + styles.activeLine : '') + (lineCustomClass ? ' ' + lineCustomClass : '') }/>
            </div> :
            <AnchorLink href={ '#' + item.href } offset='150' key={ item.title }>
              <div className={ styles.item + (' ' + item.href + '-anchorLink') }
                   onClick={ linkHandler.bind(null, item) }>
                { item.title }
                { stopFindId ? null :
                  <div
                    className={ styles.line + ' ' + (item.href === activeId ? ' ' + styles.activeLine : '') + (lineCustomClass ? ' ' + lineCustomClass : '') }/> }
              </div>
            </AnchorLink>
        ) }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  scroll: state.window.scroll,
  sizes: state.window.sizes,
  windowInnerWidth: state.window.size.windowInnerWidth,
})

export default connect(mapStateToProps)(LinkList)
