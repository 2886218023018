import { createApi as createApiClubs } from "@/store/clubs/global/actions"
import { getClubs } from "@/store/clubs/global/actions"

export const SET_SHOW_MORE_OTHER_CLUBS = 'SET_SHOW_MORE_OTHER_CLUBS'

export const getDifferentClubs = ({ pathname }) => async (dispatch, getState) => {
  const { net_id } = getState().club.global
  if (!net_id) return
  else {
    try {
      await getClubs({
        getApi: createApi.bind(null, { net_id }),
        pathname
      })(dispatch, getState)
    } catch (e) {
      console.log(e, 'Clubs is empty, it is not error')
      return 'Clubs is empty, it is not error'
    }
  }
}

export const createApi = ({ net_id }) => (dispatch, getState) => {
  let api = JSON.parse(JSON.stringify(createApiClubs()(dispatch, getState)))
  api.params = {
    net_ids: [ net_id ]
  }
  return api
}


export const setShowMore = (show = 30) => (dispatch, getState) => {
  dispatch({ type: SET_SHOW_MORE_OTHER_CLUBS, show })
}

